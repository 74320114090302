"use client";

import Logo from '@/components/logo';
import { cn } from '@/lib/utils';
import {
    ArrowLeftEndOnRectangleIcon,
    ArrowRightEndOnRectangleIcon,
    Bars3Icon,
    ChatBubbleLeftRightIcon,
    Cog6ToothIcon,
    CreditCardIcon,
    DeviceTabletIcon,
    EnvelopeIcon,
    HomeIcon,
    QuestionMarkCircleIcon,
    UserIcon,
    XMarkIcon
} from '@heroicons/react/24/outline';
import { SettingsIcon } from 'lucide-react';
import Link from "next/link";
import { useRouter } from 'next/navigation';
import { useCallback, useState } from "react";

const leftMenu = [
    { href: "/dashboard", label: "Dashboard", icon: HomeIcon, requiresAuth: true },
    { href: "/subscriptions", label: "Subscriptions", icon: CreditCardIcon, requiresAuth: true },
    {
        label: "How To",
        icon: QuestionMarkCircleIcon,
        subMenu: [
            { href: "/create-forwarding", label: "Create Mail Forwarding", icon: EnvelopeIcon },
            { href: "/authorize-kindle", label: "Authorize Kindle", icon: DeviceTabletIcon }
        ]
    },
];

const rightMenu = [
    {
        label: "Manage",
        icon: Cog6ToothIcon,
        requiresAuth: true, // Add this line
        subMenu: [
            { href: "/profile", label: "Profile", icon: UserIcon, requiresAuth: true },
            { href: "/settings", label: "Settings", icon: SettingsIcon, requiresAuth: true },
        ]
    },
    { href: "/sign-in", label: "Sign In", icon: ArrowLeftEndOnRectangleIcon, requiresAuth: false },
    { href: "/feedback", label: "Contact", icon: ChatBubbleLeftRightIcon, requiresAuth: true },
    { href: "/", label: "Sign Out", icon: ArrowRightEndOnRectangleIcon, requiresAuth: true }
];

interface NavigationProps {
    isAuthenticated: boolean;
}

export default function Navigation({ isAuthenticated }: NavigationProps) {
    const [isOpen, setIsOpen] = useState(false);
    const router = useRouter();


    const commonLinkClasses = "text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium transition-colors duration-200";
    const mobileMenuItemClasses = "text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium transition-colors duration-200";

    const handleSignOut = async (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        try {
            await fetch('/api/auth/logout', { method: 'GET' });
            router.push('/');
            router.refresh();
        } catch (error) {
            console.error('Error during sign out:', error);
        }
    };

    const handleLinkClick = useCallback(() => {
        setIsOpen(false);
    }, []);

    return (
        <nav className="bg-slate-900">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between h-16">
                    {/* Left side menu items - hidden on mobile */}
                    <div className="hidden md:flex md:items-center md:space-x-4 flex-1">
                        {leftMenu.map((item, index) => (
                            (!item.requiresAuth || (item.requiresAuth && isAuthenticated)) && (
                                item.subMenu ? (
                                    <div key={index} className="relative group">
                                        <button className={cn(commonLinkClasses, "flex items-center")}>
                                            {item.icon && <item.icon className="h-5 w-5 mr-2" />}
                                            {item.label}
                                        </button>
                                        <div className="absolute left-0 mt-2 min-w-[12rem] max-w-[20rem] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-300 ease-in-out">
                                            <div className="py-1 whitespace-nowrap" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                                {item.subMenu.map((subItem, subIndex) => (
                                                    <Link
                                                        key={subIndex}
                                                        href={subItem.href}
                                                        className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                                    >
                                                        {subItem.icon && <subItem.icon className="h-5 w-5 mr-2" />}
                                                        {subItem.label}
                                                    </Link>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <Link key={index} href={item.href} className={cn(commonLinkClasses, "flex items-center")}>
                                        {item.icon && <item.icon className="h-5 w-5 mr-2" />}
                                        {item.label}
                                    </Link>
                                )
                            )
                        ))}
                    </div>

                    {/* Logo - centered on both mobile and desktop */}
                    <div className="flex-shrink-0 flex items-center justify-center flex-1">
                        <Link href="/">
                            <Logo />
                        </Link>
                    </div>

                    {/* Right side menu items */}
                    <div className="hidden md:flex md:items-center md:space-x-4 flex-1 justify-end">
                        {rightMenu.map((item, index) => {
                            return (
                                ((item.requiresAuth && isAuthenticated) || (!item.requiresAuth && !isAuthenticated)) && (
                                    item.subMenu ? (
                                        <div key={index} className="relative group">
                                            <button className={cn(commonLinkClasses, "flex items-center")}>
                                                {item.icon && <item.icon className="h-5 w-5 mr-2" />}
                                                {item.label}
                                            </button>
                                            <div className="absolute right-0 mt-2 min-w-[12rem] max-w-[20rem] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-300 ease-in-out">
                                                <div className="py-1 whitespace-nowrap" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                                    {item.subMenu.map((subItem, subIndex) => (
                                                        <Link
                                                            key={subIndex}
                                                            href={subItem.href}
                                                            className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                                        >
                                                            {subItem.icon && <subItem.icon className="h-5 w-5 mr-2" />}
                                                            {subItem.label}
                                                        </Link>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <Link key={index} href={item.href} className={cn(commonLinkClasses, "flex items-center")} onClick={item.label === "Sign Out" ? handleSignOut : undefined}>
                                            {item.icon && <item.icon className="h-5 w-5 mr-2" />}
                                            {item.label}
                                        </Link>
                                    )
                                )
                            );
                        })}
                    </div>

                    {/* Mobile menu button */}
                    <div className="md:hidden">
                        <button
                            onClick={() => setIsOpen(!isOpen)}
                            className={cn(
                                "inline-flex items-center justify-center p-2 rounded-md text-gray-400",
                                "hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white",
                                "transition-colors duration-200"
                            )}
                        >
                            <span className="sr-only">Open main menu</span>
                            {!isOpen ? (
                                <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                            ) : (
                                <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                            )}
                        </button>
                    </div>
                </div>
            </div>

            {/* Mobile menu */}
            {isOpen && (
                <div className="md:hidden bg-slate-900">
                    <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                        {leftMenu.map((item, index) => (
                            (!item.requiresAuth || (item.requiresAuth && isAuthenticated)) && (
                                item.subMenu ? (
                                    <div key={index}>
                                        <span className={cn(mobileMenuItemClasses, "flex items-center")}>
                                            {item.icon && <item.icon className="h-5 w-5 mr-2" />}
                                            {item.label}
                                        </span>
                                        <div className="pl-4">
                                            {item.subMenu.map((subItem, subIndex) => (
                                                <Link
                                                    key={subIndex}
                                                    href={subItem.href}
                                                    className={cn(mobileMenuItemClasses, "text-sm flex items-center")}
                                                    onClick={handleLinkClick}
                                                >
                                                    {subItem.icon && <subItem.icon className="h-5 w-5 mr-2" />}
                                                    {subItem.label}
                                                </Link>
                                            ))}
                                        </div>
                                    </div>
                                ) : (
                                    <Link
                                        key={index}
                                        href={item.href}
                                        className={cn(mobileMenuItemClasses, "flex items-center")}
                                        onClick={handleLinkClick}
                                    >
                                        {item.icon && <item.icon className="h-5 w-5 mr-2" />}
                                        {item.label}
                                    </Link>
                                )
                            )
                        ))}
                        {rightMenu.map((item, index) => (
                            ((item.requiresAuth && isAuthenticated) || (!item.requiresAuth && !isAuthenticated)) && (
                                item.subMenu ? (
                                    <div key={index}>
                                        <span className={cn(mobileMenuItemClasses, "flex items-center")}>
                                            {item.icon && <item.icon className="h-5 w-5 mr-2" />}
                                            {item.label}
                                        </span>
                                        <div className="pl-4">
                                            {item.subMenu.map((subItem, subIndex) => (
                                                <Link
                                                    key={subIndex}
                                                    href={subItem.href}
                                                    className={cn(mobileMenuItemClasses, "text-sm flex items-center")}
                                                    onClick={handleLinkClick}
                                                >
                                                    {subItem.icon && <subItem.icon className="h-5 w-5 mr-2" />}
                                                    {subItem.label}
                                                </Link>
                                            ))}
                                        </div>
                                    </div>
                                ) : (
                                    <Link
                                        key={index}
                                        href={item.href}
                                        className={cn(mobileMenuItemClasses, "flex items-center")}
                                        onClick={(e) => {
                                            handleLinkClick();
                                            if (item.label === "Sign Out") {
                                                handleSignOut(e);
                                            }
                                        }}
                                    >
                                        {item.icon && <item.icon className="h-5 w-5 mr-2" />}
                                        {item.label}
                                    </Link>
                                )
                            )
                        ))}
                    </div>
                </div>
            )}
        </nav>
    );
}